import React from 'react'
import Dialog from '../../../../../components/dialog/dialog.component'
import Loading from '../../../../../components/loading/loading.component'
import AddFile from './add-file/add-file.component'
import Service from './files.service'
import './files.styles.scss'
import iconDelete from '../../../../../assets/icons/delete-red.svg'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import DeleteFile from './delete/delete.component'
import { API_ENDPOINT } from '../../../../../config'

class Files extends React.Component {

    constructor(props) {
        super(props)
        
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    onAddFileClick = e => {
        this.setState({
            ...this.state,
            showAddFile: true
        })
    }

    onCloseFileClick = () => {
        this.setState({
            ...this.state,
            showAddFile: false
        })
    }

    onAddFile = file => {
        let files = this.state.files
        files.push(file)
        this.setState({
            ...this.state,
            files: files
        })
    }

    onDelete = id => this.setState({
        ...this.state,
        showDeleteDialog: false,
        files: this.state.files.filter(file => file.id !== id)
    })

    showDeleteDialog = file => this.setState({
        ...this.state,
        showDeleteDialog: true,
        fileToDelete: file
    })

    hideDeleteDialog = () => this.setState({
        ...this.state,
        showDeleteDialog: false,
        fileToDelete: null
    })

    render() {
        return (
            <div className="files">
                {this.state.loading && <Dialog><Loading /></Dialog>}
                {this.state.showAddFile && <Dialog><AddFile id={this.props.id} onAddFile={this.onAddFile} onCancel={this.onCloseFileClick} /></Dialog>}
                {this.state.showDeleteDialog && <Dialog><DeleteFile file={this.state.fileToDelete} onDelete={this.onDelete} onCancel={this.hideDeleteDialog} /></Dialog>}
                <div className="header">
                    <h4>Files</h4>
                </div>
                <button className="btn-create" onClick={this.onAddFileClick}>Upload new file</button>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>File</th>
                            <th>Created At</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.files.map((file, key) => <tr key={key}>
                                <td>{file.name}</td>
                                <td><a href={API_ENDPOINT + '/' + file.file + '?id=' +this.props.auth.distributor.id} target="_blank" rel="noreferrer">Download File</a></td>
                                <td>{new Date(file.createdAt).toString('dd/MM/yyyy')}</td>
                                <td>
                                    <div className="actions">
                                        <img alt="" src={iconDelete} onClick={() => this.showDeleteDialog(file)} />
                                    </div>
                                </td>
                            </tr>)
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Files))