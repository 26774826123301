import React from 'react'
import { Switch, Route } from "react-router-dom"
import AuthLogin from './login/login.page'
import Register from './register/register.page'
import Reset from './reset/reset.page'

const AuthController = () => {
    return (
        <Switch>
            <Route path="/apps/recovery/:hash" component={AuthLogin} />
            <Route path="/auth/register" component={Register} />
            <Route path="/auth/reset" component={Reset} />
            <Route path="/auth" component={AuthLogin} />
        </Switch>
    )
}

export default AuthController