import iconDashboard from '../../../assets/icons/dashboard.svg'
import iconPayer from '../../../assets/icons/payer.svg'
import iconSettings from '../../../assets/icons/config.svg'

const DefaultStore = {
    showMenu: false,
    activeItem: 0,
    items: [
        {
            text: "Dashboard",
            icon: iconDashboard,
            subItems: []
        },
        {
            text: "Orders",
            icon: iconPayer,
            subItems: [
                {
                    url: "/orders/new-order",
                    text: "New Order"
                },
                {
                    url: "/orders",
                    text: "Orders"
                }
            ]
        },
        {
            text: "Account",
            icon: iconSettings,
            subItems: [
                {
                    url: "/account/profile",
                    text: "Profile"
                },
                {
                    url: "/account/change-password",
                    text: "Change Password"
                }
            ]
        },
        {
            text: "Distributor",
            icon: iconDashboard,
            subItems: [
                {
                    url: "/distributor/status",
                    text: "Status"
                },
                {
                    url: "/distributor/update",
                    text: "Update Info"
                }
            ]
        }
    ]
}

export default DefaultStore