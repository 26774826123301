import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import './status.styles.scss'
import Service from './status.service'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'

class Status extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.load()
    }

    getStatusLabel = () => {
        switch (this.state.status) {
            case 0:
                return <span></span>
            case 1:
                return <span>Pending</span>
            case 2:
                return <span>Awaiting Approval</span>
            case 3:
                return <span>Approved</span>
            case 4:
                return <span>Rejected</span>
            default:
                return <span>Unknown</span>
        }
    }

    render() {
        return (
            <>
                <div className="header">
                    <h4>Distributor's Status</h4>
                </div>
                <div className="status">
                    {this.state.loading && <Dialog><Loading /></Dialog>}
                    <h4>{this.getStatusLabel()}</h4>
                    <p>{this.state.name}</p>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Status))