import axios from 'axios'
import Validation from '../../../../data/validation/validation'
import { StringValidationRule } from '../../../../data/validation/rules'
import {ScrollIntoFirstError } from '../../../../utils/UIHelper'
import { API_ENDPOINT } from '../../../../config'

const getDefaultState = () => {
    return {
        processing: false,
        code: "",
        password: "",
        password2: "",
        errors: {},
        flag: {
            type: "success",
            text: "Verification Code sent to your email"
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.code, (error) => instance.state.errors.code = error, { min: { value: 6, error: "Invalid" }, max: { value: 6, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.password, (error) => instance.state.errors.password = error, { min: { value: 6, error: "Invalid" }, max: { value: 40, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.password2, (error) => instance.state.errors.password2 = error, { min: { value: 6, error: "Invalid" }, max: { value: 40, error: "Invalid" } })
    let validate = validation.validate()
    if(validate && instance.state.password !== instance.state.password2) {
        validate = false
        alert("Passwords do not match")
    }
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const setup = (instance) => {
    instance.setState({
        ...instance.state,
        processing: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + instance.props.url,
        headers: {
            "Authorization": instance.props.authorization
        },
        data: {
            code: instance.state.code,
            password: instance.state.password
        }
    }).then(response => handleSetupResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                processing: false
            })
            alert(error)
        })
}

const handleSetupResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.props.history.push('/auth/login')
            break
        default:
            instance.setState({
                ...instance.state,
                processing: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        setup: () => setup(instance)
    }
}

export default Service