import axios from 'axios'
import Validation from '../../../data/validation/validation'
import { StringValidationRule } from '../../../data/validation/rules'
import { ScrollIntoFirstError } from '../../../utils/UIHelper'
import { API_ENDPOINT } from '../../../config'

const getDefaultState = () => {
    return {
        loading: false,
        saving: false,
        name: "",
        rcNumber: "",
        office: "",
        warehouse: "",
        tin: "",
        bvn: "",
        category: "",
        bankGuaranteeProvided: false,
        coverageZones: [],
        bankers: [],
        cylinderTypes: [],
        files: [],
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.name, (error) => instance.state.errors.name = error, { min: { value: 3, error: "Invalid" }, max: { value: 200, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.rcNumber, (error) => instance.state.errors.rcNumber = error, { min: { value: 4, error: "Invalid" }, max: { value: 10, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.office, (error) => instance.state.errors.office = error, { min: { value: 3, error: "Invalid" }, max: { value: 300, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.warehouse, (error) => instance.state.errors.warehouse = error, { min: { value: 3, error: "Invalid" }, max: { value: 300, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.tin, (error) => instance.state.errors.tin = error, { min: { value: 10, error: "Invalid" }, max: { value: 11, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.bvn, (error) => instance.state.errors.bvn = error, { min: { value: 11, error: "Invalid" }, max: { value: 11, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.category, (error) => instance.state.errors.category = error, { min: { value: 1, error: "Invalid" }, max: { value: 1, error: "Invalid" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const load = (instance) => {
    instance.setState({
        ...instance.state,
        loading: true
    })
    axios({
        method: 'get',
        url: API_ENDPOINT + "/distributors/self",
        headers : {
            "Authorization": instance.props.auth.authorization
        }
    }).then(response => handleLoadResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loading: false
            })
            alert(error)
        })
}

const handleLoadResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loading: false,
                name: response.data.data.distributor.name,
                rcNumber: response.data.data.distributor.rcNumber,
                office: response.data.data.distributor.office,
                warehouse: response.data.data.distributor.warehouse,
                tin: response.data.data.distributor.tin,
                bvn: response.data.data.distributor.bvn,
                category: response.data.data.distributor.category,
                bankGuaranteeProvided: response.data.data.distributor.isBankGuaranteeProvided,
                bankers: response.data.data.distributor.bankers,
                coverageZones: response.data.data.distributor.coverageZones,
                cylinderTypes: response.data.data.distributor.cylinderTypes,
                files: response.data.data.distributor.files
            })
            break
        default:
            instance.setState({
                ...instance.state,
                loading: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const save = (instance) => {
    instance.setState({
        ...instance.state,
        saving: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/distributors/update",
        headers : {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            name: instance.state.name,
            rcNumber: instance.state.rcNumber,
            office: instance.state.office,
            warehouse: instance.state.warehouse,
            tin: instance.state.tin,
            bvn: instance.state.bvn,
            bankGuaranteeProvided: instance.state.bankGuaranteeProvided,
            category: instance.state.category,
            coverageZones: instance.state.coverageZones,
            bankers: instance.state.bankers,
            cylinderTypes: instance.state.cylinderTypes
        }
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(error)
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "success",
                    text: "Saved"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        load: () => load(instance),
        save: () => save(instance)
    }
}

export default Service