import React from 'react'
import './confirm.styles.scss'
import Service from './confirm.service'
import FormHelper from '../../../../utils/FormHelper'
import Form from './form.component'
import NumberFormat from 'react-number-format'

class ConfirmOrder extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    onSubmit = e => {
        e.preventDefault()
        this.service.isValid() && this.props.onConfirm(this.state.notes)
    }

    render() {
        return (
            <div className="card confirm">
                <div className="header">
                    <h4>Confirm Order of <NumberFormat value={this.props.total} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} /></h4>
                </div>
                <div className={"flag " + this.state.flag.type}>
                    {this.state.flag.text}
                </div>
                <Form {...this.state} onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} onCancel={this.props.onCancel}/>
            </div>
        )
    }
}

export default ConfirmOrder