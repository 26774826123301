import React from 'react'
import { Switch, Route } from "react-router-dom"
import Update from './update/update.page'
import Status from './status/status.page'

const DistributorController = () => {
    return (
        <div className="distributor">
            <Switch>
                <Route path="/distributor/status" component={Status} />
                <Route path="/distributor/update" component={Update} />
            </Switch>
        </div>
    )
}

export default DistributorController