import React from 'react'
import { connect } from 'react-redux'
import './dashboard.styles.scss'
import imageDashbord from '../../assets/icons/dashboard.svg'
import imageOrders from '../../assets/icons/payer.svg'
import imageSecurity from '../../assets/icons/security.svg'
import imageSettings from '../../assets/icons/config.svg'
import { withRouter } from 'react-router-dom'
import Service from './dashboard.service'

class Dashboard extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="dashboard">
                    <div className="cards">
                        <p>loading...</p>
                    </div>
                </div>
            )
        }
        return (
            <div className="dashboard">
                <div className="cards">
                    <div className="card membership" onClick={() => this.props.history.push("/distributor/status")}>
                        <div>
                            <img alt="" src={imageDashbord} />
                        </div>
                        <div>
                            <strong>Distributorship Status</strong>
                            <span>check</span>
                        </div>
                    </div>
                    <div className="card program" onClick={() => this.props.history.push("/distributor/update")}>
                        <div>
                            <img alt="" src={imageSettings} />
                        </div>
                        <div>
                            <strong>Distributor Info</strong>
                            <span>update</span>
                        </div>
                    </div>
                    <div className="card applications" onClick={() => this.props.history.push("/orders")}>
                        <div>
                            <img alt="" src={imageOrders} />
                        </div>
                        <div>
                            <strong>Orders</strong>
                            <span>view</span>
                        </div>
                    </div>
                    <div className="card points" onClick={() => this.props.history.push("/account/change-password")}>
                        <div>
                            <img alt="" src={imageSecurity} />
                        </div>
                        <div>
                            <strong>Account</strong>
                            <span>change password</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Dashboard))