

const getDefaultState = () => {
    return {
        loading: false
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance)
    }
}

export default Service