import React from 'react'

const Form = props => {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="inputs">
                <div className="row" id="type">
                    <div className="form-label">
                        <label>Name</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="name" value={props.name} onChange={props.onChange} />
                        <span className="error">{props.errors.name}</span>
                    </div>
                </div>
                <div className="row" id="file">
                    <div className="form-label">
                        <label>File</label>
                    </div>
                    <div className="form-input">
                        <input type="file" name="file" accept="application/pdf" onChange={e => {
                            if(e.target.files.length > 0){
                                props.onChange({
                                    target: {
                                        name: "file",
                                        value: e.target.files[0]
                                    }
                                })
                            }
                        }} />
                        <span className="error">{props.errors.file}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="submit">Upload</button>
                <button type="reset" onClick={props.onCancel}>Cancel</button>
            </div>
        </form>
    )
}

export default Form