
const getDefaultState = instance => {
    return {
        loading: false,
        showAddAsset: false,
        files: instance.props.data
    }
}

const Worker = instance => {
    return {
        getDefaultState: () => getDefaultState(instance)
    }
}

export default Worker