import React from 'react'
import Checkbox from '../../../../components/inputs/checkbox/checkbox.component'
import './form.styles.scss'

const Form = (props) => {
    return (
        <form>
            <div className="inputs">
                <div className="fl-row">
                    <div className="col-50">
                        <div className="row" id="name">
                            <div className="form-label">
                                <label>Name</label>
                            </div>
                            <div className="form-input">
                                <input type="text" name="name" value={props.name} onChange={props.onChange} />
                                <span className="error">{props.errors.name}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-50">
                        <div className="row" id="rcNumber">
                            <div className="form-label">
                                <label>RC Number</label>
                            </div>
                            <div className="form-input">
                                <input type="text" name="rcNumber" value={props.rcNumber} onChange={props.onChange} />
                                <span className="error">{props.errors.rcNumber}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fl-row">
                    <div className="col-50">
                        <div className="row" id="office">
                            <div className="form-label">
                                <label>Office</label>
                            </div>
                            <div className="form-input">
                                <textarea type="text" name="office" value={props.office} onChange={props.onChange} />
                                <span className="error">{props.errors.office}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row col-50" id="warehouse">
                        <div className="form-label">
                            <label>Warehouse</label>
                        </div>
                        <div className="form-input">
                            <textarea type="text" name="warehouse" value={props.warehouse} onChange={props.onChange} />
                            <span className="error">{props.errors.warehouse}</span>
                        </div>
                    </div>
                </div>
                <div className="fl-row">
                    <div className="row col-50" id="tin">
                        <div className="form-label">
                            <label>TIN</label>
                        </div>
                        <div className="form-input">
                            <input type="text" name="tin" value={props.tin} onChange={props.onChange} />
                            <span className="error">{props.errors.tin}</span>
                        </div>
                    </div>
                    <div className="row col-50" id="bvn">
                        <div className="form-label">
                            <label>M.D BVN</label>
                        </div>
                        <div className="form-input">
                            <input type="text" name="bvn" value={props.bvn} onChange={props.onChange} />
                            <span className="error">{props.errors.bvn}</span>
                        </div>
                    </div>
                </div>
                <div className="fl-row">
                    <div className="row col-50" id="category">
                        <div className="form-label">
                            <label>Category</label>
                        </div>
                        <div className="form-input">
                            <select name="category" value={props.category} onChange={props.onChange}>
                                <option value=""></option>
                                <option value="1">Distributor (1, 000 Cylinders/month)</option>
                                <option value="2">Super Distributor (5, 000 Cylinders/month)</option>
                                <option value="3">Mega Distributor (10, 000 Cylinders/month)</option>
                            </select>
                            <span className="error">{props.errors.category}</span>
                        </div>
                    </div>
                    <div className="row col-50" id="bankGuaranteeProvided">
                        <div className="form-label">
                            <label>Are you providing Bank Guarantee</label>
                        </div>
                        <Checkbox name="bankGuaranteeProvided" text={props.bankGuaranteeProvided ? 'Yes' : 'No'} checked={props.bankGuaranteeProvided} onChange={() => {
                            props.onChange({
                                target: {
                                    name: "bankGuaranteeProvided",
                                    value: !props.bankGuaranteeProvided
                                }
                            })
                        }} />
                    </div>
                </div>
            </div>
        </form>
    )
}

export default Form