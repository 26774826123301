import axios from 'axios'
import { API_ENDPOINT } from '../../../config'

const getDefaultState = () => {
    return {
        loading: false,
        status: 0,
        name: ""
    }
}

const load = (instance) => {
    instance.setState({
        ...instance.state,
        loading: true
    })
    axios({
        method: 'get',
        url: API_ENDPOINT + "/distributors/self",
        headers: {
            "Authorization": instance.props.auth.authorization
        }
    }).then(response => handleLoadResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loading: false
            })
            alert(error)
        })
}

const handleLoadResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loading: false,
                name: response.data.data.distributor.name,
                status: response.data.data.distributor.status
            })
            break
        default:
            instance.setState({
                ...instance.state,
                loading: false
            })
            alert(response.data.message)
    }
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        load: () => load(instance)
    }
}

export default Service