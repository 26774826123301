import { StringValidationRule } from "../../../../data/validation/rules"
import Validation from "../../../../data/validation/validation"

const getDefaultState = () => {
    return {
        notes: "",
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.notes, (error) => errors.notes = error, { min: { value: 0, error: "Invalid" }, max: { value: 300, error: "Invalid" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance)
    }
}

export default Service