import axios from 'axios'
import { API_ENDPOINT } from '../../../config'

const getDefaultState = () => {
    return {
        loading: false,
        order: {
            invoices: [
                {
                    paid: 0,
                    balance: 0,
                    payments: []
                }
            ],
            orderLines: [],
            placedBy: {}
        }
    }
}

const loadOrder = instance => {
    axios({
        method: 'get',
        url: API_ENDPOINT + "/orders/" + instance.props.match.params.number,
        headers : {
            "Authorization": instance.props.auth.authorization
        }
    }).then(response => handleLoadOrderResponse(instance, response))
    .catch(error => alert(error))
}

const handleLoadOrderResponse = (instance, response) => {
    switch(response.data.status){
        case 200:
            instance.setState({
                ...instance.state,
                loading: false,
                order: response.data.data.order
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            alert(response.data.message)
    }
}

const Service = instance => {
    return {
        loadOrder: () => loadOrder(instance),
        getDefaultState: () => getDefaultState(instance)
    }
}

export default Service