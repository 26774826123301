import { API_ENDPOINT } from '../../../../../../config'
import axios from 'axios'
import { StringValidationRule, FileValidationRule } from "../../../../../../data/validation/rules"
import Validation from "../../../../../../data/validation/validation"

const getDefaultState = () => {
    return {
        uploading: false,
        name: "",
        file: null,
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.name, (error) => errors.name = error, { min: { value: 3, error: "Invalid" }, max: { value: 50, error: "Invalid" } })
    validation.addValidationRule(FileValidationRule, instance.state.file, (error) => errors.file = error, { allowNull: false })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const addFile = instance => {
    instance.setState({
        ...instance.state,
        uploading: true,
        flag: {
            type: "",
            text: ""
        }
    })

    let formData = new FormData()
    formData.append('name', instance.state.name)
    formData.append('file', instance.state.file)

    axios({
        method: 'post',
        url: API_ENDPOINT + "/distributors/add-file",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: formData
    }).then(response => handleAddFileResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                uploading: false
            })
            alert(error)
        })
}

const handleAddFileResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                uploading: false
            })
            instance.props.onAddFile(response.data.data.file)
            instance.props.onCancel()
            break
        default:
            instance.setState({
                ...instance.state,
                uploading: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const Worker = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        addFile: () => addFile(instance)
    }
}

export default Worker