import React from 'react'
import { Switch, Route } from "react-router-dom"
import NewOrder from './new-order/new-order.page'
import Orders from './orders.page'
import Details from './details/details.page'
import RequireActivation from '../../components/require-activation/require-activation.component'

const OrderController = () => {
    return (
        <Switch>
            <Route path="/orders/new-order" component={() => <RequireActivation><NewOrder/></RequireActivation>} />
            <Route path="/orders/:number" component={Details} />
            <Route path="/orders" component={Orders} />
        </Switch>
    )
}

export default OrderController