import axios from 'axios'
import Validation from '../../../data/validation/validation'
import { StringValidationRule, EmailValidationRule } from '../../../data/validation/rules'
import { ScrollIntoViewById } from '../../../utils/UIHelper'
import { API_ENDPOINT } from '../../../config'

const getDefaultState = () => {
    return {
        processing: false,
        username: "",
        password: "",
        rememberMe: false,
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const IsValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(EmailValidationRule, instance.state.username, (error) => instance.state.errors.username = error, { allowNull: false, error: "Invalid" })
    validation.addValidationRule(StringValidationRule, instance.state.password, (error) => instance.state.errors.password = error, { min: { value: 6, error: "Invalid" }, max: { value: 40, error: "Invalid" } })

    instance.setState({
        ...instance.state,
        errors: errors
    }, () => {
        for (let error in instance.state.errors) {
            if (instance.state.errors[error].length > 0) {
                ScrollIntoViewById(error)
                return
            }
        }
    })
    return validation.validate()
}

const login = (instance) => {
    processing(instance, true)
    axios({
        method: 'post',
        url: API_ENDPOINT + "/auth/distributors/login",
        data: {
            username: instance.state.username,
            password: instance.state.password
        }
    }).then(response => handleLoginResponse(instance, response))
        .catch(error => {
            processing(instance, false)
            alert(error)
        })
}

const processing = (instance, isProcessing) => {
    instance.setState({
        ...instance.state,
        processing: isProcessing
    })
}

const handleLoginResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            sessionStorage.setItem("auth", JSON.stringify(response.data.data.access))
            if(response.data.data.access.distributor.status !== 3){
                instance.props.history.push('/distributor/update')
            }
            else{
                instance.props.history.push('/')
            }
            break
        default:
            instance.setState({
                ...instance.state,
                processing: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const Worker = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => IsValid(instance),
        processing: isProcessing => processing(instance, isProcessing),
        login: () => login(instance)
    }
}

export default Worker