import React from 'react'
import {withRouter } from 'react-router-dom'

const Form = (props) => {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="form-input">
                <label>Firstname</label>
                <input type="text" name="firstname" value={props.firstname} onChange={props.onChange} />
                <span className="error">{props.errors.firstname}</span>
            </div>
            <div className="form-input">
                <label>Lastname</label>
                <input type="text" name="lastname" value={props.lastname} onChange={props.onChange} />
                <span className="error">{props.errors.lastname}</span>
            </div>
            <div className="form-input">
                <label>Phone</label>
                <input type="text" name="phone" value={props.phone} onChange={props.onChange} />
                <span className="error">{props.errors.phone}</span>
            </div>
            <div className="form-input">
                <label>Company</label>
                <input type="text" name="company" value={props.company} onChange={props.onChange} />
                <span className="error">{props.errors.company}</span>
            </div>
            <div className="form-input">
                <label>Email (username)</label>
                <input type="email" name="email" value={props.email} onChange={props.onChange} />
                <span className="error">{props.errors.email}</span>
            </div>
            <div className="actions">
                <button className={props.processing ? 'disabled' : ''}>Continue</button>
            </div>
        </form>
    )
}

export default withRouter(Form)